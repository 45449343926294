function renderHorizontalRuler() {
  let pixelList = document.getElementById("horizontalList");
  let screenWidthByHundreds = Math.floor(
    document.documentElement.clientWidth / 100
  );

  let i = 0;

  while (screenWidthByHundreds > 0) {
    let li = document.createElement("li");
    li.append(i * 100);
    pixelList.appendChild(li);
    i++;
    screenWidthByHundreds--;
  }
}

function renderVerticalRulerRuler() {
  let pixelList = document.getElementById("verticalList");
  let screenHeightByHundreds = Math.floor(
    document.documentElement.clientHeight / 100
  );

  let i = 1;

  while (screenHeightByHundreds > 0) {
    let li = document.createElement("li");
    li.append(i * 100);
    pixelList.appendChild(li);
    i++;
    screenHeightByHundreds--;
  }
}

function renderRulers() {
  cleanUp();
  renderHorizontalRuler();
  renderVerticalRulerRuler();
}

function cleanUp() {
  let elements = document.getElementById("verticalList");
  while (elements.firstChild) {
    elements.removeChild(elements.lastChild);
  }

  elements = document.getElementById("horizontalList");
  while (elements.firstChild) {
    elements.removeChild(elements.lastChild);
  }
}

renderRulers();
window.onresize = renderRulers;
